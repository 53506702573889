var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-lazy',[_c('div',[_c('h2',[_vm._v(_vm._s(_vm.translate('Supplier Info')))]),_c('v-data-table',{attrs:{"dense":"","items-per-page":20,"headers":_vm.supplierTableHeaders,"items":[_vm.order],"hide-default-footer":true,"item-key":"name"}}),_c('br'),_c('br'),_c('br'),_c('h2',[_vm._v(_vm._s(_vm.translate('Checkin Info')))]),_c('v-divider'),_c('v-row',[_c('v-col',[_c('span',[_vm._v(_vm._s(_vm.translate('Received at')))]),_c('span',[_c('h3',[_vm._v(_vm._s(_vm.formatDate(_vm.order.date)))])])]),_c('v-col',[_c('span',[_vm._v(_vm._s(_vm.translate('Payment Status'))+" ")]),_c('span',{staticClass:"font-weight-medium v-chip v-size--small",class:_vm.order.color},[_c('span',{staticClass:"v-chip__content"},[_vm._v(" "+_vm._s(_vm.order.status)+" ")])])])],1),_c('br'),_c('h3',[_vm._v(_vm._s(_vm.translate('Stocks')))]),_c('v-data-table',{attrs:{"dense":"","items-per-page":20,"headers":_vm.stocksTableHeaders,"items":_vm.order.stocks,"hide-default-footer":true,"item-key":"name"},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.amount)+" "+_vm._s(item.measurement_name))]}},{key:"item.quantity_price",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.quantity_price)+" "+_vm._s(_vm.currency))]}},{key:"item.tax",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.tax)+"%")]}},{key:"item.discount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.discount)+"%")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.calculateTotal(item))+" "+_vm._s(_vm.currency))]}}])}),_c('br'),_c('br'),_c('div',{staticStyle:{"padding-left":"20px"}},[_c('h4',{staticClass:"text-decoration-none",staticStyle:{"font-weight":"500"}},[_vm._v(" "+_vm._s(_vm.translate('Sub Total'))+": "+_vm._s(_vm.getSubTotal())+" "+_vm._s(_vm.currency)+" ")]),_c('h4',{staticClass:"text-decoration-none",staticStyle:{"font-weight":"500","padding":"0"}},[_vm._v(" "+_vm._s(_vm.translate('Total Discount'))+": "+_vm._s(_vm.getDiscount())+" "+_vm._s(_vm.currency)+" ")]),_c('h4',{staticClass:"text-decoration-none",staticStyle:{"font-weight":"500","margin":"0"}},[_vm._v(" "+_vm._s(_vm.translate('Total Tax'))+": "+_vm._s(_vm.getTax())+" "+_vm._s(_vm.currency)+" ")])]),_c('v-divider'),_c('div',{staticClass:"d-flex justify-space-between flex-wrap",staticStyle:{"padding-left":"10px"}},[_c('h3',{staticStyle:{"font-weight":"600"}},[_vm._v(_vm._s(_vm.translate('Grand Total'))+": "+_vm._s(_vm.getGrandTotal())+" "+_vm._s(_vm.currency))]),_c('v-btn',{staticStyle:{"margin":"5px"},attrs:{"color":_vm.get_payment_button_color},on:{"click":_vm.toggleSupplierPayment}},[_vm._v(_vm._s(_vm.get_payment_button_text))])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }