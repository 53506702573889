<template>
  <div>
    <div class="d-flex justify-end" :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4'" flat tile>
      <div style="width: 80%"></div>
      <v-select
        class="pa-2"
        outlined
        filled
        dense
        v-model="dateRange"
        @input="dateRange => dateRangeChanged(dateRange)"
        :items="['This Month', 'Last Month', 'Today', 'Yesterday', 'This Year', 'Last Year', 'Custom Date']"
      ></v-select>
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            label="Custom Date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker range v-model="date" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
          <v-btn text color="primary" @click="dateRangeChanged(date, 'customDate')"> OK </v-btn>
        </v-date-picker>
      </v-menu>
    </div>
    <v-row>
      <v-col>
        <v-card class="elevation-0">
          <div style="padding: 10px">
            <table>
              <h2>Designers - Completed Orders</h2>
              <tr v-for="designer in getData.designers_completed" :key="designer.name">
                <td>
                  <a @click="designerPressed(designer.id)"
                    ><h4>{{ designer.name }}</h4></a
                  >
                </td>
                <td>{{ designer.orders }} Orders</td>
              </tr>
            </table>
          </div>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <div style="padding: 10px">
            <table>
              <h2>Producers - Completed Orders</h2>
              <tr v-for="designer in getData.producers_completed" :key="designer.name">
                <td>
                  <a @click="producerPressed(designer.id)"
                    ><h4>{{ designer.name }}</h4></a
                  >
                </td>
                <td>{{ designer.orders }} Orders</td>
              </tr>
            </table>
          </div>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <div style="padding: 10px">
            <table>
              <h2>Drivers - Completed Orders</h2>
              <tr v-for="designer in getData.drivers_completed" :key="designer.name">
                <td>
                  <a @click="driverPressed(designer.id)"
                    ><h4>{{ designer.name }}</h4></a
                  >
                </td>
                <td>{{ designer.orders }} Orders</td>
              </tr>
            </table>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <div style="padding: 10px">
            <table>
              <h2>Sales Agent - Completed Orders</h2>
              <tr v-for="designer in getData.sales_agent_completed" :key="designer.name">
                <td>
                  <a @click="salesAgentCompletedPressed(designer.id)"
                    ><h4>{{ designer.name }}</h4></a
                  >
                </td>
                <td>{{ designer.orders }} Orders</td>
              </tr>
            </table>
          </div>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <div style="padding: 10px">
            <table>
              <h2>Sales Agent - Refused Orders</h2>
              <tr v-for="designer in getData.sales_agent_refused" :key="designer.name">
                <td>
                  <a @click="salesAgentRefusedPressed(designer.id)"
                    ><h4>{{ designer.name }}</h4></a
                  >
                </td>
                <td>{{ designer.orders }} Orders</td>
              </tr>
            </table>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <br /><br />
    <v-data-table
      :loading="tableLoading"
      sort-by.sync="order_status"
      sort-desc.sync="true"
      :search="search"
      :items-per-page="20"
      :headers="headers"
      :items="computedOrders"
      class="elevation-1"
    >
      <template v-slot:item.order_status="{ item }">
        <span class="font-weight-medium v-chip v-size--small" :class="item.color"
          ><span class="v-chip__content"> {{ orderStatusToText(item.order_status) }} </span></span
        >
      </template>
      <template v-slot:item.deadline="{ item }">
        <span>{{ formatDate(item.deadline) }}</span
        >&nbsp;
        <span class="font-weight-medium v-chip v-size--small primary"
          ><span class="v-chip__content"> {{ timeAgo(item.deadline) }} </span></span
        >
      </template>
      <template v-slot:item.id="{ item }">
        <v-dialog width="60%">
          <template v-slot:activator="{ on, attrs }">
            <a color="red lighten-2" dark v-bind="attrs" v-on="on">
              {{ item.id }}
            </a>
          </template>
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">{{ translate('Order') }} #{{ item.id }}</v-card-title>
            <v-card-text>
              <OrderInfo @setNewData="setNewData" :currency="currency" :order="item"></OrderInfo>
            </v-card-text>
            <v-divider></v-divider>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:item.actions="{ item }">
        <router-link :to="{ name: 'edit_order', params: { id: item.id } }">
          <!--
             <v-icon style="color: var(--v-warning-base)" small> mdi-pencil </v-icon>
           -->
        </router-link>
        <v-dialog :retain-focus="false" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click.stop="item.delete_dialog = true"
              small
              style="color: var(--v-error-base)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-delete
            </v-icon>
          </template>

          <v-card>
            <v-card-title class="text-h5"
              >{{ translate('Are you sure want to delete Order') }} {{ item.id }}?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="delete_dialog = false">{{ translate('Cancel') }}</v-btn>
              <v-btn color="primary" @click="handleDelete(item.id)">{{ translate('OK') }}</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </v-data-table>
    <div class="text-center">
      <downloadExcel
        class="elevation-0 v-btn v-size--small v-btn--outlined v-btn--tile"
        :data="computedOrders"
        :fields="{
          order_id: 'id',
          order_status: 'order_status',
          order_created_at: 'order_created_at',
          deadline: 'deadline',
          order_completed_at: 'order_completed_at',
          magazine: 'magazine_name',
          created_by_user_id: 'created_by_user_id',
          driver_id: 'driver_id',
          delivery_fee: 'delivery_fee',
          driver_name: 'driver_name',
          driver_phone_number: 'driver_phone_number',
          client_name: 'client_name',
          client_phone_number: 'client_phone',
          client_address: 'client_address',
          client_lat: 'client_lat',
          client_lng: 'client_lng',
          order_status: 'order_status_formatted',
          reason: 'refuse_reason',
          origin: 'origin_type',
          total_price: 'total_price',
          profit: 'profit',
        }"
      >
          Export Excel   
      </downloadExcel>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { ref } from '@vue/composition-api'
import OrderInfo from '@/views/pages/orders/OrderInfo.vue'
import CheckinInfo from './CheckinInfo.vue'

import {
  deleteExpense,
  deleteOrder,
  getCheckins,
  getCurrency,
  getDesignerOrders,
  getDriverOrders,
  getEmployeesReport,
  getExpenses,
  getProductionOrders,
  getProfitLoss,
  getSalesOrders,
} from '@/ApiManager'
export default {
  components: {
    OrderInfo,
  },
  computed: {
    computedOrders() {
      return this.orders
    },
    getData() {
      return this.data
    },
  },

  data() {
    return {
      tableLoading: false,
      dateRange: 'This Month',
      date: null,
      menu: null,
      orders: [],
      data: {},
      currency: '',
      tab: null,
      search: '',
      headers: [
        {
          align: 'start',
          value: 'name',
        },
        { text: this.translate('Order Number'), value: 'id' },
        { text: this.translate('Deadline'), value: 'deadline' },
        { text: this.translate('Customer Name'), value: 'client_name' },
        { text: this.translate('Magazine'), value: 'magazine_name' },
        { text: this.translate('Order Status'), value: 'order_status' },
        { text: this.translate('Total Price'), value: 'total_price' },
        { text: this.translate('Actions'), value: 'actions' },
      ],
    }
  },
  methods: {
    setNewData() {},
    async driverPressed(id) {
      this.tableLoading = true
      getDriverOrders(id, this.dateRange).then(response => {
        this.orders = response.data
        this.tableLoading = false
      })
    },
    async salesAgentCompletedPressed(id) {
      this.tableLoading = true
      getSalesOrders(id, this.dateRange, 'completed').then(response => {
        this.orders = response.data
        this.tableLoading = false
      })
    },
    async salesAgentRefusedPressed(id) {
      this.tableLoading = true
      getSalesOrders(id, this.dateRange, 'refused').then(response => {
        this.orders = response.data
        this.tableLoading = false
      })
    },
    async designerPressed(id) {
      this.tableLoading = true
      getDesignerOrders(id, '"completed","refused"', this.dateRange).then(response => {
        this.orders = response.data
        this.tableLoading = false
      })
    },
    async producerPressed(id) {
      this.tableLoading = true

      getProductionOrders(id, '"completed","refused"', this.dateRange).then(response => {
        this.orders = response.data
        this.tableLoading = false
      })
    },
    dateRangeChanged(dateRange, customDate) {
      if (dateRange == 'Custom Date' && customDate == undefined) return
      this.$store.state.loading = true
      if (customDate != undefined) {
        this.dateRange = 'Custom Date'
      }
      getEmployeesReport(this.dateRange).then(response => {
        this.data = response
        this.$store.state.loading = false
      })
    },
  },
  created() {
    this.$store.state.loading = true
    getCurrency().then(response => {
      this.currency = response.currency
    })

    getEmployeesReport(this.dateRange).then(response => {
      this.data = response
      this.$store.state.loading = false
    })
  },
}
</script>

<style>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: var(--v-primaryLighter-base);
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 20px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 16 px !important;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  display: none;
}
</style>
