<template>
  <v-lazy>
    <div>
      <h2>{{ translate('Supplier Info') }}</h2>
      <v-data-table
        dense
        :items-per-page="20"
        :headers="supplierTableHeaders"
        :items="[order]"
        :hide-default-footer="true"
        item-key="name"
      ></v-data-table>

      <br />
      <br />
      <br />
      <h2>{{ translate('Checkin Info') }}</h2>
      <v-divider></v-divider>
      <v-row>
        <v-col
          ><span>{{ translate('Received at') }}</span>
          <span
            ><h3>{{ formatDate(order.date) }}</h3></span
          ></v-col
        >
        <v-col
          ><span>{{ translate('Payment Status') }} </span>
          <span class="font-weight-medium v-chip v-size--small" :class="order.color"
            ><span class="v-chip__content"> {{ order.status }} </span></span
          >
        </v-col>
      </v-row>
      <br />
      <h3>{{ translate('Stocks') }}</h3>
      <v-data-table
        dense
        :items-per-page="20"
        :headers="stocksTableHeaders"
        :items="order.stocks"
        :hide-default-footer="true"
        item-key="name"
      >
        <template v-slot:item.amount="{ item }">{{ item.amount }} {{ item.measurement_name }}</template>
        <template v-slot:item.quantity_price="{ item }">{{ item.quantity_price }} {{ currency }}</template>
        <template v-slot:item.tax="{ item }">{{ item.tax }}%</template>
        <template v-slot:item.discount="{ item }">{{ item.discount }}%</template>
        <template v-slot:item.total="{ item }">{{ calculateTotal(item) }} {{ currency }}</template>
      </v-data-table>
      <br /><br />
      <div style="padding-left: 20px">
        <h4 class="text-decoration-none" style="font-weight: 500">
          {{ translate('Sub Total') }}: {{ getSubTotal() }} {{ currency }}
        </h4>
        <h4 class="text-decoration-none" style="font-weight: 500; padding: 0">
          {{ translate('Total Discount') }}: {{ getDiscount() }} {{ currency }}
        </h4>
        <h4 class="text-decoration-none" style="font-weight: 500; margin: 0">
          {{ translate('Total Tax') }}: {{ getTax() }} {{ currency }}
        </h4>
      </div>
      <v-divider></v-divider>
      <div class="d-flex justify-space-between flex-wrap" style="padding-left: 10px">
        <h3 style="font-weight: 600">{{ translate('Grand Total') }}: {{ getGrandTotal() }} {{ currency }}</h3>
        <v-btn @click="toggleSupplierPayment" :color="get_payment_button_color" style="margin: 5px">{{
          get_payment_button_text
        }}</v-btn>
      </div>
    </div>
  </v-lazy>
</template>
<script>
import { LMap, LTileLayer, LMarker, LTooltip, LPolyline, LIcon } from 'vue2-leaflet'
import { Icon } from 'leaflet'
import { mdiGoogle } from '@mdi/js'
import axios from 'axios'
import { getCheckins, toggleSupplierPayment } from '@/ApiManager'

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
export default {
  props: ['order', 'currency'],

  computed: {
    get_payment_button_text() {
      if (this.order.supplier_paid == 1) {
        return this.translate('Supplier not Paid')
      } else {
        return this.translate('Supplier is Paid')
      }
    },
    get_payment_button_color() {
      if (this.order.supplier_paid == 1) {
        return 'error'
      } else {
        return 'success'
      }
    },
  },
  methods: {
    async toggleSupplierPayment() {
      this.$store.state.loading = true
      var state = this.order.supplier_paid == 1 ? 0 : 1
      await toggleSupplierPayment(this.order.id, state)

      this.$emit('setCheckinData')

    },
    calculateTotal(stock) {
      var price = 0
      //get total price
      price = stock.amount * stock.quantity_price
      //calculate discount
      price = price - (price * stock.discount) / 100
      //calculate tax
      price = price + (price * stock.tax) / 100
      return price
    },
    getSubTotal() {
      var price = 0
      for (var i = 0; i < this.order.stocks.length; i++) {
        price += this.order.stocks[i].quantity_price * this.order.stocks[i].amount
      }
      return price
    },
    getDiscount() {
      var price = 0
      for (var i = 0; i < this.order.stocks.length; i++) {
        var cost = this.order.stocks[i].quantity_price * this.order.stocks[i].amount
        price += (cost * this.order.stocks[i].discount) / 100
      }
      return price
    },
    getTax() {
      var price = 0
      for (var i = 0; i < this.order.stocks.length; i++) {
        var cost = this.order.stocks[i].quantity_price * this.order.stocks[i].amount
        cost -= (cost * this.order.stocks[i].discount) / 100
        price += (cost * this.order.stocks[i].tax) / 100
      }
      return price
    },
    getGrandTotal() {
      var price = 0
      for (var i = 0; i < this.order.stocks.length; i++) {
        var stock = this.order.stocks[i]
        //get total price
        var cost = stock.amount * stock.quantity_price
        //calculate discount
        cost = cost - (cost * stock.discount) / 100
        //calculate tax
        cost = cost + (cost * stock.tax) / 100
        price += cost
      }
      return price
    },
  },
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',

      zoom: 50,
      markerLatLng: [0, 0],
      leaflet_key: 0,
      supplierTableHeaders: [
        { text: 'ID', value: 'supplier_id' },
        { text: this.translate('Name'), value: 'supplier_name' },
        { text: this.translate('Phone Number'), value: 'supplier_phone_number' },
      ],

      stocksTableHeaders: [
        { text: this.translate('Product'), value: 'product_name' },
        { text: this.translate('Quantity Price'), value: 'buy_price' },
        { text: this.translate('Amount'), value: 'amount' },
        { text: this.translate('Discount'), value: 'discount' },
        { text: this.translate('Tax'), value: 'tax' },
        { text: this.translate('Total'), value: 'total' },
      ],
    }
  },
  components: {
    LMap,
    mdiGoogle,
    LTileLayer,
    LMarker,
    LTooltip,
    LPolyline,
    LIcon,
  },
  created() {},
}
</script>
